<template>
    <div>
        <div v-if="isIcon" class="d-flex align-items-center">
            <IconLoader :icon="index.icon" size="xs" :style="iconSize" />
        </div>
        <span v-else class="d-flex align-items-top" :class="{ 'number-hide': indexInfo.hidden }" v-html="index" />
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import IconLoader from '../../stories/components/icons/IconLoader.vue'
import { mapGetters } from 'vuex'

interface IconSize {
    height: string
    width: string
    minHeight: string
    minWidth: string
}

interface IndexInfo {
    number: number
    format: number
    hidden?: boolean
    numbering_restarted?: boolean
}

export default defineComponent({
    name: 'NumberFormatIndex',
    components: { IconLoader },
    props: {
        indexInfo: {
            type: Object as PropType<IndexInfo>,
            required: true,
        },
        resizable: {
            type: Boolean,
            default: false,
        },
        customSize: {
            type: [String, Number],
            default: '',
        },
    },

    computed: {
        ...mapGetters('document', ['documentStyle']),
        index() {
            return this.$store.getters['document/format_number'](
                this.indexInfo.number,
                this.indexInfo.format || 1,
                this.indexInfo.format,
            )
        },

        isIcon(): boolean {
            return typeof this.index === 'object'
        },
        iconSize(): IconSize | null {
            let size = { minHeight: '11px', minWidth: '11px' }

            if (this.resizable) return { ...size, height: this.documentStyle.font_size, width: this.documentStyle.font_size }
            if (this.customSize) return { ...size, height: this.customSize.toString(), width: this.customSize.toString() }

            return null
        },
    },
})
</script>
